import { Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { BrowserCacheLocation,
        InteractionType,  
         IPublicClientApplication, 
         LogLevel, 
         PublicClientApplication,
         ILoggerCallback } from '@azure/msal-browser';
import { env } from 'src/config/env';
import { dukeEnergyAzureADTenantId, environments } from 'src/config/env.config';
import { AzureUserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public userClaims: BehaviorSubject<AzureUserModel> = new BehaviorSubject<AzureUserModel>({});

  constructor() {
    //console.log(`Your environment is set to ${this.config.getConfig('envName')}`);
  }

  //This does not appear to be used??
  //----------------------------------------------
  //fetchUserInfo(): Observable<AzureUserModel> {
  //  var account = this.getUserAccount();
  //  if (account != null && account != undefined) {
  //    this.userClaims.next(account);
  //  }
  //  return of(account);
  //}

  getUserAccount(): any {
    var userInfo = typeof sessionStorage.getItem('CCB::ACTIVE_ACCOUNT') == 'undefined' ? null : sessionStorage.getItem('CCB::ACTIVE_ACCOUNT');
    return JSON.parse(userInfo);
  }

  /****************************************************************************
   * This (ID not Access Token) gets added to our request header by 
   * authInterceptor. ID Token comes from session storage, placed there by
   * call to  handleMSALToken().
   ***************************************************************************/
  public getUserToken(): string{
    //return this.getUserAccount()?.idToken; 
    return this.getUserAccount()?.accessToken; //QAJ 8/30/24 changed to use accessToken
  }

  //boolean (true/false)
  public isAuthenticated(): boolean {
    return this.getUserAccount().isLoggedIn;
  }

  public getUserName(): string {
    return this.getUserAccount().name;
  }

  public getUserEmail(): string {
    return this.getUserAccount().mail;
  } 

}// end class


/******************************************************************************************
 *  Instance Factory Provider to Define our IPublicClientApplication (Boilerplate - no mods)
 *****************************************************************************************/
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environments[env.env].azureClientId,
      authority: 'https://login.microsoftonline.com/' + dukeEnergyAzureADTenantId,
      redirectUri: '/', //Go back to page you started at
      postLogoutRedirectUri: environments[env.env].unauthorizedRoute
    },
    cache: {
      cacheLocation: BrowserCacheLocation.MemoryStorage,
      storeAuthStateInCookie: true 
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        //logLevel: LogLevel.Info, 
        //logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}//end MSALInstanceFactory()

export const loggerCallback: ILoggerCallback = (level: number, message: string) => {
  console.log(`From MSAL: ${message}`);
};

/******************************************************************************************
 * Factory Provider for MSAL Interceptor Configuration - modified 
 * protectedResourceMap scope to User.Read (otherwise we get an authority challenge)
 *****************************************************************************************/
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  
  const protectedResourceMap = new Map<string, Array<string>>(); 

  //These values are specific for each environment. 
  protectedResourceMap.set(environments[env.env].apiBaseUrl, ['App.ReadWrite.All', 
                                                              'AzureCLI_Auth', 
                                                              'User.Read.All',
                                                              'User.Read']); //QAJ 8/30/24 Changed api permissions
    //JCG 9/4/24 Changed api permissions (Per QAJ recommendations)
    protectedResourceMap.set('https://wl3gzj6cac.execute-api.us-east-1.amazonaws.com/dev', 
                                  ['api://4289e761-59df-4360-807c-b7a83b6786f0/AzureCLI_Auth', 
                                  'api://4289e761-59df-4360-807c-b7a83b6786f0/App.ReadWrite.All']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}//end MSALInterceptorConfigFactory()
  
/******************************************************************************************
 * Factory Provider for MSAL Guard Configuration. 
 * JCG 11/20/23 changed scope from User.Read.All to User.Read. This appears to be the only 
 * scope that will open the app. (Boilerplate - modified scopes to User.Read)
 *****************************************************************************************/
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['User.Read','offline_access'] //JCG 8/29/24
        //scopes:['App.ReadWrite.All'] //Boilerplate
      },
      loginFailedRoute: environments[env.env].unauthorized
    };
  }