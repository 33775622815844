import { Injectable } from "@angular/core";
import { ReliabilityService } from "src/app/services/reliability.service";

@Injectable()
export class AppSense {

  //------------Events------------------
  private pendingChanges:   boolean = false;
  private blnInfoEdits:     boolean = false;
  private blnImpactEdits:   boolean = false;
  private blnActionEdits:   boolean = false;
  private blnATagEdits:     boolean = false;
  private blnVTagEdits:     boolean = false;
  //--------------------------------------
  private blnDeleteAction:  boolean = false;
  private blnDeleteImpact:  boolean = false; 
  private blnAddAction:     boolean = false;
  private blnAddImpact:     boolean = false;
  //-------------------------------------
  private dataDisplay:      string = 'table'; 
  private impactsLbl:       string = '';
  private actionsLbl:       string = '';

  //----------Look-Aheads----------------- JCG 10/21/24
  private blnLaDetailEdits: boolean = false;
  private blnLaRow1Edits:   boolean = false;
  private blnLaRow2Edits:   boolean = false;
  private blnLaRow3Edits:   boolean = false;
  private blnLaRow4Edits:   boolean = false;

  //------Updates/Follow-Ups--------------
  private blnUpInfoEdits:   boolean = false;
  private blnUpActionEdits: boolean = false;
  private blnUpAdd:         boolean = false;
  private blnUpDelete:      boolean = false;

  private blnFollowUpEdits: boolean = false;
  private blnAddFollowUp:   boolean = false;
  private blnDeletFollowUp: boolean = false;   


  constructor(private reliabilityService: ReliabilityService){}

  //#########################################################################
  //                            EVENTS
  //#########################################################################

  //Labels are modified to tell user of pending edits that need to be saved.
  get infoLabel(){
      return this.blnInfoEdits ? 'Info*' : 'Info';
  }

  //  Business Impact Tab Label showing impact count
  //-----------------------------------------------------SET
  set impactsLabel(numImpacts: string) {
    if (numImpacts == null) {
      this.impactsLbl = 'Impacts(0)';
    } else {
      this.impactsLbl = 'Impacts(' + numImpacts + ')';
    }
  }

  //  Business Impact Tab shows pending edits
  //-----------------------------------------------------GET
  get impactsLabel(){
      return this.blnImpactEdits ? this.impactsLbl + '*' : this.impactsLbl;
  }

  //  Corrective Actions Tab Label showing actions count
  //-----------------------------------------------------SET
  set actionsLabel(numActions: string) {
    if (numActions == null) {
      this.actionsLbl = 'Actions(0)';
    } else {
      this.actionsLbl = 'Actions(' + numActions + ')';
    }
  }

  //  Corrective Actions Tab shows pending edits
  //-----------------------------------------------------GET
  get actionsLabel(){
      return this.blnActionEdits ? this.actionsLbl + '*' : this.actionsLbl;
  }

  //  Critical App Tab Label shows pending edits.
  //-----------------------------------------------------GET
  get aTagLabel(){
      return this.blnATagEdits ? 'Critical App Tags*' : 'Critical App Tags';
  }

  //  Vendor Tab Label shows pending edits.
  //-----------------------------------------------------GET
  get vTagLabel(){
      return this.blnVTagEdits ? 'Vendor Tags*' : 'Vendor Tags';
  }

  /******************************************************************
   * Event Edit Markers (Used to add change flag '*' to Tabs)
   *****************************************************************/
  //---Event Info Tab
  get pendingEventEdits() :boolean {
    return this.blnInfoEdits;
  }
  set pendingEventEdits(blnTmp: boolean){
    this.blnInfoEdits = blnTmp;
  }
  //---Corrective Actions
  get pendingActionEdits() :boolean {
    return this.blnActionEdits;
  }
  set pendingActionEdits(blnTmp: boolean){
    this.blnActionEdits = blnTmp;
  }
  //---Business Impacts
  get pendingImpactEdits() :boolean {
    return this.blnImpactEdits;
  }
  set pendingImpactEdits(blnTmp: boolean){
    this.blnImpactEdits = blnTmp;
  }
  //---Critical App Tags
  get pendingATagEdits() :boolean {
    return this.blnATagEdits;
  }
  set pendingATagEdits(blnTmp: boolean){
    this.blnATagEdits = blnTmp;
  }
  //---Vendor Tags
  get pendingVTagEdits() :boolean {
    return this.blnVTagEdits;
  }
  set pendingVTagEdits(blnTmp: boolean){
    this.blnVTagEdits = blnTmp;
  }

  /******************************************************************
   * Corrective Actions Add/Delete Flags used in refreshing the 
   * corrective actions tab after an update or delete action.
   *****************************************************************/
  get actionAddFlag() {
    return this.blnAddAction;
  }
  set actionAddFlag(blnTmp: boolean) {
    this.blnAddAction = blnTmp;
  } 
  get actionDeleteFlag() {
    return this.blnDeleteAction;
  }
  set actionDeleteFlag(blnTmp: boolean) {
    this.blnDeleteAction = blnTmp;
  }

  /******************************************************************
   * Business Impact Add/Delete Flags used in refreshing the Biz
   * Impact tab after an update or delete action.
   *****************************************************************/  
  get impactAddFlag() : boolean {
    return this.blnAddImpact;
  }
  set impactAddFlag(blnTmp: boolean) {
    this.blnAddImpact = blnTmp;
  }
  get impactDeleteFlag() {
    return this.blnDeleteImpact;
  }
  set impactDeleteFlag(blnTmp: boolean) {
    this.blnDeleteImpact = blnTmp;
  }

  /****************************************************************
   *  Sets Main display (table or list)
   ****************************************************************/
  get eventDisplay() : string {
    return this.dataDisplay;
  }

  set eventDisplay(dsp: string) {
    this.dataDisplay = dsp;
  }

  // Used to determine if there are any unsaved Event changes.
  isPendingChange(){
      if(this.blnInfoEdits){
          //console.log('blnInfoEdits');
          return true;
      } else if(this.blnActionEdits){
          //console.log('blnActionEdits');
          return true;
      } else if(this.blnImpactEdits){
          //console.log('blnImpactEdits');
          return true;
      } else if(this.blnATagEdits){
          //console.log('blnATagEdits');
          return true;
      } else if (this.blnVTagEdits){
          //console.log('blnVTagEdits');
          return true;
      } else {
          return false;
      }
  }//end isPendingChange

  resetEditFlags(){
    this.blnInfoEdits = false;
    this.blnActionEdits = false;
    this.blnImpactEdits = false;
    this.blnATagEdits = false;
    this.blnVTagEdits = false;
}

  //#########################################################################
  //                         LOOK-AHEADS
  //#########################################################################

  /******************************************************************
   * Look-Ahead Edit Markers (Used to add change flag '*' to Tabs)
   *****************************************************************/ 
  //---Look-Ahead Details
  get pendingLaDetailEdits() :boolean {
    return this.blnLaDetailEdits;
  }
  set pendingLaDetailEdits(blnTmp: boolean){
    this.blnLaDetailEdits = blnTmp;
  }
  //---Row 1 HTML
  get pendingLaRow1Edits() :boolean {
    return this.blnLaRow1Edits;
  }
  set pendingLaRow1Edits(blnTmp: boolean){
    this.blnLaRow1Edits = blnTmp;
  }
  //---Row 2 HTML
  get pendingLaRow2Edits() :boolean {
    return this.blnLaRow2Edits;
  }
  set pendingLaRow2Edits(blnTmp: boolean){
    this.blnLaRow2Edits = blnTmp;
  }
  //---Row 3 HTML
  get pendingLaRow3Edits() :boolean {
    return this.blnLaRow3Edits;
  }
  set pendingLaRow3Edits(blnTmp: boolean){
    this.blnLaRow3Edits = blnTmp;
  }
  //---Row 4 HTML
  get pendingLaRow4Edits() :boolean {
    return this.blnLaRow4Edits;
  }
  set pendingLaRow4Edits(blnTmp: boolean){
    this.blnLaRow4Edits = blnTmp;
  }
  //--------Look-Ahead Tab Labels------------------------------------
  get lookaheadDetailsTab() :string {
    return this.blnLaDetailEdits ? 'Look-Ahead Titles*' : 'Look-Ahead Titles';
  }
  getLookaheadRow1Tab(str: string) :string {
    return this.blnLaRow1Edits ? str + '*' : str;
  }
  getLookaheadRow2Tab(str: string) :string {
    return this.blnLaRow2Edits ? str + '*' : str;
  }
  getLookaheadRow3Tab(str: string) :string {
    return this.blnLaRow3Edits ? str + '*' : str;
  }
  getLookaheadRow4Tab(str: string) :string {
    return this.blnLaRow4Edits ? str + '*' : str;
  }

  //Used to determine if there are any unsaved Look-Ahead changes.
  isPendingLookAheadChange(){
    if(this.blnLaDetailEdits){
        return true;
    } else if(this.blnLaRow1Edits){
        return true;
    } else if(this.blnLaRow2Edits){
        return true;
    } else if(this.blnLaRow3Edits){
        return true;
    } else if (this.blnLaRow4Edits){
        return true;
    } else {
        return false;
    }
}//end isPendingLookAheadChange

  resetLookAheadEdits() {
    this.blnLaDetailEdits = false;
    this.blnLaRow1Edits = false;
    this.blnLaRow2Edits = false;
    this.blnLaRow3Edits = false;
    this.blnLaRow4Edits = false;
  }

  //#########################################################################
  //                      UPDATES/FOLLOW-UPS
  //#########################################################################

  //Updates Info Tab Label
  get followupInfoLabel() :string {
    return this.blnUpInfoEdits ? 'Info*' : 'Info';
  }

  //Updates-Follow-up Action Tab Label shows pending edits.
  get followUpActionLabel() :string {
    return this.blnUpActionEdits ? 'Follow-Up Actions*' : 'Follow-Up Actions';
  }

  /******************************************************************
   * Follow-Up Edit Markers (Used to add change flag '*' to Tabs)
   *****************************************************************/

  // Follow-Up Info Tab
  get pendingUpInfoEdits () :boolean {
    return this.blnUpInfoEdits;
  }
  set pendingUpInfoEdits (blnTmp: boolean) {
    this.blnUpInfoEdits = blnTmp;
  }

  get pendingUpActionEdits () :boolean {
    return this.blnUpActionEdits;
  }
  set pendingUpActionEdits (blnTmp: boolean) {
    this.blnUpActionEdits = blnTmp;
  }

  /******************************************************************
   * Update Actions (Follow-Up) Add/Delete Flags used in refreshing  
   * the Update Actions tab after an update or delete action.
   *****************************************************************/
  get updateAddFlag() {
    return this.blnAddFollowUp;
  } 
  set updateAddFlag(blnTmp: boolean) {
    this.blnAddFollowUp = blnTmp; 
  }
  get updateDeleteFlag() {
    return this.blnDeletFollowUp;
  }
  set updateDeleteFlag(blnTmp: boolean) {
    this.blnDeletFollowUp = blnTmp;
  }

  isPendingUpdateChange() :boolean {
    if(this.blnUpInfoEdits){
      return true;
    } else if(this.blnUpActionEdits){
      return true;
    } else {
      return false;
    }
  }

  resetFollowupEdits() {
    this.blnUpInfoEdits = false;
    this.blnUpActionEdits = false;
  }

  //#########################################################################
  //                      GLOBAL APP SETTINGS
  //#########################################################################

  /****************************************************************
   *  Used to determine the load page spinner message. Controls
   *  standard message length (important). The centering of this text
   *  is controlled by ../styles/style.css
   ****************************************************************/
  get fetchMessage() :string {
    return 'Fetching Data....One Moment.';
  }
  get saveMessage() :string {
    return 'Saving Data......One Moment.';
  }
  get deleteMessage() :string {
    return 'Deleting Date....One Moment.';
  }
  get sendMessage() :string {
    return 'Sending Request..One Moment.';
  }

}//end class