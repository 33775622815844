/*************************************************************
 * JCG - 3/1/2024 - This service is used as a utility service
 * that contains methods common to the reliability app and
 * can be used by multiple components. It also can be used to
 * maintain state (such as the week ending value).
 *************************************************************/
import { Injectable } from '@angular/core';
import { EventModel } from '../models/event.model';

//@Injectable({
//  providedIn: 'root'
//})
export class ReliabilityService {

  /*************************************
   * To Switch endPoints change the 
   * environment value.
   * valid values are PROD,QA,DEV,LOCAL
   *************************************/
  private environment: string = "LOCAL" //not currently used.
  //------------------------------------

  private timeZoneOffset: number = 4;
  private weekEnding:     string = '';
  private blnNewEvent:    boolean = false;
  private blnCloneEvent:  boolean = false;
  private blnAppTags:     boolean = false;
  private blnVendorTags:  boolean = false;

  //private nextSeqVal:   number = 0; //not used.
  private strSearchTitle: string = '';
  private strAdminTitle:  string = '';
  private prodEndpoint:   string = 'https://3f136d8gz6.execute-api.us-east-1.amazonaws.com/prod/api/';
  private qaEndpoint:     string = 'https://f8ux11iqhb.execute-api.us-east-1.amazonaws.com/qa/api/';
  private devEndpoint:    string = 'https://wl3gzj6cac.execute-api.us-east-1.amazonaws.com/dev/api/';
  private localEndpoint:  string = 'http://localhost:5058/api/';
  //Error Dialog Text------------------------------------------
  private errTitle:       string = '';
  private errMessage:     string = '';
  //Standard delay for token retry.
  private sleep_delay:     number = 500;
  private confirmation_delay: number = 1500;

  constructor() { }

  //JCG - 3/5/24
  /*****************************************************************
   * Accepts a string mm/dd/yyyy and saves the value.
   ****************************************************************/
  set WeekEnding(dt: string) {
    this.weekEnding = dt;
  }
  //JCG - 3/5/24
  /*****************************************************************
   * Returns the saved week ending value. Will hold the value until
   * the App is refreshed. 
   *****************************************************************/
  get WeekEnding() :string {
    if (this.weekEnding == null || this.weekEnding == undefined
        || this.weekEnding.length == 0) {
      this.weekEnding = this.getNewWeekEnding();
    }
    return this.weekEnding;
  }

  /***************************************************************
   * Flag used to distinguish between "Add New" or "Edit Existing"
   * action since both navigate to the same component. If this
   * is true, we make a call to get the next DB sequence.
   **************************************************************/
  set isNewEvent(blnFlag: boolean) {
    this.blnNewEvent = blnFlag
  }
  get isNewEvent() :boolean {
    return this.blnNewEvent;
  }

  /******************************************************************
   * Flag used to determine if a user is cloning an existing
   * event.
   *****************************************************************/
  set isClonedEvent(blnFlag: boolean) {
    this.blnCloneEvent = blnFlag;
  }
  get isClonedEvent() :boolean {
    return this.blnCloneEvent;
  }

  /******************************************************************
   *  Data Search Page Title
   *****************************************************************/
  set searchTitle(strTitle: string){
    this.strSearchTitle = strTitle;
  }
  get searchTitle() :string {
    return this.strSearchTitle;
  }

  /******************************************************************
   *  Admin Tab Page Title
   *****************************************************************/
  set adminTitle(strTitle: string){
    this.strAdminTitle = strTitle;
  }
  get adminTitle() :string {
    return 'Administrative Pages - ' + this.strAdminTitle;
  }

  /******************************************************************
   * Critical App Tag Count
   *****************************************************************/
  set appTagFlag(blnInput: boolean){
    this.blnAppTags = blnInput;
  }
  get appTagFlag() :boolean {
    return this.blnAppTags;
  }

  /******************************************************************
   * Vendor Tag Count
   *****************************************************************/
  set vendorTagFlag(blnInput: boolean){
    this.blnVendorTags = blnInput;
  }
  get vendorTagFlag() :boolean {
    return this.blnVendorTags;
  }

  /******************************************************************
   *  API Error Dialog Text JCG - 7/23/24
   *****************************************************************/
  get apiErrorDialogTitle() {
    this.errTitle = 'Woops!......Something Went Wrong :('
    return this.errTitle
  }

  get apiErrorMsg() {
    this.errMessage = 'There was a problem with your request!!  '
    this.errMessage += 'If the problem persists after Retry, Press [Ctrl + Shift + i] to view the error console in your browser. '
    this.errMessage += 'Please take a screen shot of what you see and report the problem to your Administrator.'
    return this.errMessage;
  }

  /*****************************************************************
   * Standard Application delay (1000 ms)
   ****************************************************************/
  get sleepDelay() {
    return this.sleep_delay;
  } 
  
  /*****************************************************************
   * Data Saved Confirmation linger duration (before auto-close)
   ****************************************************************/
  get confirmDelay() :number {
    return this.confirmation_delay; //2 seconds.
  }

  /*****************************************************************
   * Returns appropriate endpoint (not currently used)
  *****************************************************************/
  getEndpoint(svc: string) {
    let endpoint = '';
    switch(this.environment) {
      case 'PROD': endpoint = this.prodEndpoint + svc;
      break;
      case 'QA': endpoint = this.qaEndpoint + svc;
      break;
      case 'DEV': endpoint = this.devEndpoint + svc;
      break;
      case 'LOCAL': endpoint =  this.localEndpoint + svc;
      break;
      default : endpoint =  this.localEndpoint + svc;
      break;
      //console.log('Endpoint: ', endpoint);
      return endpoint;
    }
  }


  // JCG - 3/5/24
  /******************************************************************
   *  Determines the next upcoming Thursday
   *  0 = Sun, 1 = Mon, 2 = Tue, 3 = Wed, 4 = Thu, 5 = Fri, 6 = Sat
   *  Returns an mm/dd/yyyy formatted string.
   * **************************************************************/
  getNewWeekEnding() {
    var date: Date = new Date();
    var thursday: Date = new Date();
    var today = date.getDay();
    var addDays = 0;
    //------------------------------
    if (today <= 5) {               //(5) We want Friday to reflect last weeks events.
      addDays = (4 - today);
    }
    else {
      addDays = (11 - today);
    }
    //-------------------------------
    thursday.setDate(date.getDate() + addDays);
    var week_day = thursday.getDate();
    var week_month = thursday.getMonth() + 1;
    var week_year = thursday.getFullYear();
    //--------------------------------
    if (week_day < 10){
      var strDay = '0' + week_day.toString();
    }
    else {
      var strDay = week_day.toString();
    }
    if (week_month < 10) {
      var strMon = '0' + week_month.toString();
    }
    else {
      var strMon = week_month.toString();
    }
    var fmtThurs = strMon + '/' + strDay + '/' + week_year;
    return fmtThurs;
  }

  /******************************************************************
   *  Takes getNewWeekEnding results and coverts the value to a 
   *  date object to be used for eventData.weekEnding replacement.
   *****************************************************************/
  getNewWeekEndingDateObj() {
    //MM/DD/YYYY
    let dateStr = this.getNewWeekEnding();
    //console.log('dateStr: ',dateStr);
    let month = dateStr.substring(0,2);
    let day = Number(dateStr.substring(3,5));
    let year = dateStr.substring(6,10);
    //let dttm = year + '-' + month + '-' + day + ' EDT';
    let dttm = month + '/' + day + '/' + year;
    //console.log('dttm: ', dttm);
    return new Date(dttm);
  }

  //dateCellValueFormatter(params: ValueFormatterParams) {
  //  return params.value ? new Date(params.value).toLocaleDateString('en', { timeZone: 'UTC' }) : '';
  //}

  weekEndingFormatter(dt: Date) {
    return dt ? new Date(dt).toLocaleDateString('en', { timeZone: 'UTC' }) : '';
  }

  /******************************************************************
   * Used to combine date-picker and time-picker selection values
   * into a single datetime string. (2024-04-04T04:00:00Z)
   ******************************************************************/
  insertTime( dateObj: Date, strTime: string) {

    let strDay = this.padNumber(dateObj.getDate());
    let strMonth = this.padNumber(dateObj.getMonth() + 1);  
    let strYear = dateObj.getFullYear();
    let newTime = this.toMilitaryTime(strTime);
    let newDate =  strYear + '-' + strMonth + '-' + strDay + 'T' + newTime + ':00Z';
    return new Date(newDate);
  }

  /******************************************************************
   * Used with Date/Time Picker.
   * When User only changes the time. Used to change the time value
   * in the unchanged date string which contains the old time.
   * Incoming format: (2024-04-12T04:00:00Z, 4:30 PM)
   *****************************************************************/
  editTime( dateStr: string, strTime: string) {
    //extract just the date from date string
    let strDate = dateStr.substring(0,10);
    let newTime = this.toMilitaryTime(strTime);
    let newDate = strDate + 'T' + newTime + ":00Z";
    //console.log("edtTime: ", newDate);
    return new Date(newDate);
  }

  /*********************************************************
   * Pads time values (1 - 9) to (01 - 09)
   ********************************************************/
  padNumber( strNum: number) {
    if (strNum < 10){
      return '0' + strNum.toString();
    } else {
      return strNum.toString();
    }
  }

 
  /***********************************************************
  * Converts conventional time to military timne.
  * Accepts time format of: 4:35 PM and converts to: 16:35
  ***********************************************************/
  toMilitaryTime(strTime: string){
    
    //get the hour from time 
    let strHour = strTime.substring(0,2);

    //find the position of the :
    let minPos = strTime.indexOf(":") + 1;

    //find the minutes in the string
    let strMin = strTime.substring(minPos,minPos + 2);

    //strips the alpha chars out of the time string to get AM/PM
    let strAmPm = strTime.replace(/[^a-z]/gi, '');

    //Extracts only the number(s) in case the string contains :
    strHour = strHour.match(/\d+/)[0];
    let intHour = Number(strHour);

    //Convert 12HH to 24HH
    if (strAmPm === 'PM' && intHour < 12) {
      intHour += 12;
    } else if (strAmPm === 'AM' && intHour === 12) {
      intHour = 0;
    } 
    
    return this.padNumber(intHour) + ':' + strMin;
  }

  /***************************************************************
   * Converts military time to conventional time
   * 16:35 = 4:35 PM
   **************************************************************/
  convertTime(tm: string) {
    var timeString: string;
    var minutes: string;

    let hourPart = Number(tm.slice(0,2));
    let minPart = Number(tm.slice(3));
    //----------------------------------------------
    if (minPart < 10) {
      minutes = '0' + minPart;
    }
    else {
      minutes = minPart.toString();
    }
  //-----------------------------------------------
    if (hourPart == 0) {
		timeString = '12:' + minutes + ' AM';
    }
    else if (hourPart > 0 && hourPart < 12) {
      timeString = hourPart + ':' + minutes + ' AM';
    } else if (hourPart == 12) {
      timeString = hourPart + ':' + minutes + ' PM';
    }
    else {
        timeString = (hourPart -12) + ':' + minutes + ' PM';
    }
    //----------------------------------------------
    return timeString;
  }

  /***********************************************************
   * Accepts a date string or date Object and returns in a 
   * format of: Mon, 4/19/2024
   **********************************************************/
  formatDate(dt: any){
    let cdate = "";

    if(typeof dt === 'string'){
      let cdateObj = new Date(dt);
      cdate = cdateObj.toDateString();
    }
    if(typeof dt === 'object') {
      cdate = dt.toDateString();
    }
    //at this point we should have:  Fri Mar 01 2024, as a string.
    let strDay = cdate.substring(0,3);
    let strMon = cdate.substring(4,7);
    let intDom = (cdate.substring(8,10)).replace(/^0+/,"");
    let strYear = cdate.substring(11);
    let intMon = this.getMonthIndex(strMon);

    return strDay + ', ' + intMon + '/' + intDom + '/' + strYear;
  }

  /***********************************************************
   * Accepts a date string or date Object and returns in a 
   * format of: 4:39 PM. Offset = true adjusts for GMT.
   **********************************************************/
  formatTime(dt: any, offset: boolean){
    let cdateObj = new Date();
    //let timeOffSet = 4;  //probably a better way to do this. (GMT +4 = EDT)
    let hours = 0;

    if(typeof dt === 'string'){
      cdateObj = new Date(dt); 
    }
    if(typeof dt === 'object') {
      cdateObj = dt;
    }

    if(offset){
      hours = cdateObj.getHours() + this.timeZoneOffset; //adjust for timezone.
    }else {
      hours = cdateObj.getHours()  
    }
  
    if(hours == 24){
      hours = 0;
    }

    let minutes = cdateObj.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; //converts hour 0 to 12.
    let cminutes = minutes < 10 ? '0'+ minutes : minutes;
    let ctime = hours + ':' + cminutes + ' ' + ampm;
    return ctime;
  }


  /***********************************************************
   * Accepts a month abbreviation and returns its index.
   * Mar returns 3, Jul returns 7.....
  ************************************************************/
  getMonthIndex(strMon: string){
    let monthString = "Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec"
    let j = 1;
    const monthArray = monthString.split(',');
    for(let i = 0; i < monthArray.length; i++){
      if(strMon === monthArray[i]){
        break;
      }else{
        j++;
      }
    }
    return j;
  }

  /***********************************************************
   * Takes a datetime string of: 	2024-04-08T07:51:00Z
   * and converts to (Dow,MM/DD/YYYY HH:MM AM/PM)
   **********************************************************/
  formatDateTime(dt: any){
    if(dt == null) {
      return 'TBD';
    } else {
      let dayDate = this.formatDate(dt);
      //above should give us: Mon, 4/19/2024 now we need to format the time.
      let dayTime = this.formatTime(dt, true);
      return dayDate + ' ' + dayTime;
    }
  }

  /***********************************************************
   * Accepts a Postgres Date Object (2024-07-18T00:00:00Z)
   * and converts it to a mm/dd/yyyy format. Conversion from
   * Postgres Date to Javbascript Date looses a day??? So we
   * must add it back.
   ***********************************************************/
  shortDate(dt: any) :string {
    let cdateObj = new Date(dt);
    cdateObj.setDate(cdateObj.getDate() + 1); //compensates for diff between postgres and javascript.
    let _day = this.padNumber(cdateObj.getDate());
    let _month = this.padNumber(cdateObj.getMonth() + 1);
    let _year = cdateObj.getFullYear();
    let cdate = _month + '/' + _day + '/' + _year
    return cdate;
    }

  /***********************************************************
   * Used to determine default due date for Follow-ups which
   * is always a Monday.
   **********************************************************/
  getFollowUpMonday() {
    const duePeriod = 18;
    let dtReportDate = new Date(this.getNewWeekEnding());
    dtReportDate.setDate(dtReportDate.getDate() + duePeriod);
    return dtReportDate;
  }

  /***********************************************************
  * The date picker appears to have issues with a PostgreSQL
  * date format of 2024-04-08T07:51:00Z that causes 
  * inaccuracies. This function extracts the date, reformats
  * it to an MM/DD/YYYY Date which the date picker seems to
  * accept accurately.
  **********************************************************/
  reformatDate(dttm: Date) {
    let tmpDttm = dttm.toString();
    let year = tmpDttm.substring(0,4);
    let month = (tmpDttm.substring(5,7)).replace(/^0+/,''); //trim leading zeros.
    let day = tmpDttm.substring(8,10);
    let strDate = month + '/' + day + '/' + year;
    let newDttm = new Date(strDate);
    return newDttm;
  }

  /***********************************************************
   * Checks date for null, undefined or 0 length string
   **********************************************************/ 
  isValidDate(dt: any){
    let cdate = "";

    if (dt === null || dt === undefined){
      return false
    }
    if(typeof dt === 'string'){
      let cdateObj = new Date(dt);
      cdate = cdateObj.toDateString();
    }
    if(typeof dt === 'object') {
      cdate = dt.toDateString();
    } 
    if( dt.length === 0) {
      return false;
    }
    return true;
  }  

  /***********************************************************
   * Determines email greeting based on time of day
   **********************************************************/
  get_Greeting() {
    const d = new Date();
    let hour = d.getHours();
    //console.log('hour: ', hour);
    let strTmp = '';
    if(hour >= 0 && hour < 12){
      strTmp = 'Good morning,';
    }else if (hour >= 12 && hour < 17) {
      strTmp = 'Good afternoon,';
    }else {
      strTmp = 'Good evening,';
    }
    return strTmp;
  }

  getRequestDueDate(date=null) {
    var today : Date = date == null? new Date() : date;
    //console.log('today.getDay: ', today.getDay());
    switch (today.getDay()) {
      //Fri
      case 5:
      //Sat
      case 6:
      //Sun
      case 0: return '9:00 AM, ' + this.getNextWeekDay(1).toDateString(); 
      //Mon
      case 1: return '9:00 AM, ' + this.getNextWeekDay(2).toDateString();
      //Tue
      case 2: return '9:00 AM, ' + this.getNextWeekDay(3).toDateString();
      //Wed
      case 3: return '9:00 AM, ' + this.getNextWeekDay(4).toDateString();
      //Thu
      case 4: 
        if (today.getTime() > new Date(today.getFullYear(),today.getMonth(),today.getDate(),16,0,0).getTime()) {
          return 'ASAP Today';
        } else if (new Date(today.getFullYear(),today.getMonth(),today.getDate(),10,0,0).getTime() > today.getTime()) {
          return '12:00 PM, ' + today.toDateString();
        } else {
        return 'ASAP Today';
        }
      default: return 'ASAP Today';
    }
  }

  getNextWeekDay(day: number) {
    return new Date(new Date().setDate(new Date().getDate() + (day - 1 - new Date().getDay() + 7) % 7 + 1));
  }

  getCurrentDateTime(){
    let dt = new Date();
    dt.setHours(dt.getHours() - this.timeZoneOffset);
    return dt;
  }


  getPostgresOffset(dttm: Date) {
    dttm.setHours(dttm.getHours() - this.timeZoneOffset);
    return dttm;
  }

  /****************************************************
   * Delay routine, use the following code to call this:
   * sleep(2000).then(() => {code to be executed here..; });
   ****************************************************/
  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve,ms));
  }

  /***************************************************
   * JCG 10/3/24 Used to address issue CCIR-1346,
   * This method removes all hidden characters from
   * a given text string. In this case the Email 
   * Subject which was causing the .NET EventController
   * to throw a SystemAugmentException in rare cases.
   ***************************************************/
  cleanText(strTxt: string) :string {
    strTxt = strTxt.replace(/[\u0000-\u001F\u007F-\u009F\u061C\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, "");
    return strTxt;
  }  

  isEmpty(str: string) :boolean {
    if (str === undefined) {
      return true;
    } else if (str === null) {
      return true;
    } else if (str.replace(/\s/g,"").length == 0 ) {
      return true;
    } else {
      return false;
    }
  }

}//end class
