/*************************************************************************
 *  JCG - 4/17/2024 Added for Page Loading Spinner. Detects HTTP requests.
 *  Must be placed in the app.module.ts, otherwise it never detects 
 *  anything.
 *************************************************************************/
import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { finalize } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(private loadingService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): 
  Observable<HttpEvent<unknown>> {
    this.totalRequests++;
    this.loadingService.setLoading(true);
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if(this.totalRequests == 0){
          this.loadingService.setLoading(false);
        }
      })
    );
  }
}