//JCG 7/24/24
export const dukeEnergyAzureADTenantId = '2ede383a-7e1f-4357-a846-85886b2c0c4d';

export const environments = {
    local: {
        production: false,
        envName: 'local',
        //apiBaseUrl: 'https://f8ux11iqhb.execute-api.us-east-1.amazonaws.com/qa/api/', //JCG 7/26/24
        apiBaseUrl: 'https://graph.microsoft.com/v2.0/me', // QAJ 8/29/24 Updated apiBaseUrl
        azureClientId: '060cdbe9-e3cb-446e-9905-834750e784bc', //CC-Reliability-QA CID
        unauthorizedRoute: "/unauthorized"
    },
    sbx: {
        production: false,
        envName: 'sbx',
        apiBaseUrl: 'https://emp-dev-api.duke-energy.com/your-proxy-name/',
        azureClientId: '@AZURE-CLIENT-ID@', //replace values script will replace from vault
        unauthorizedRoute: "/unauthorized"
    },
    dev: {
        production: false,
        envName: 'dev',
        //apiBaseUrl: 'https://wl3gzj6cac.execute-api.us-east-1.amazonaws.com/dev/api/',
        apiBaseUrl: 'https://graph.microsoft.com/v2.0/me', // QAJ 8/29/24 Updated apiBaseUrl
        azureClientId: '4289e761-59df-4360-807c-b7a83b6786f0', //replace values script will replace from vault
        unauthorizedRoute: "/unauthorized"
    },
    tst: {
        production: false,
        envName: 'test',
        apiBaseUrl: 'https://emp-tst-api.duke-energy.com/your-proxy-name/',
        azureClientId: '@AZURE-CLIENT-ID@',
        unauthorizedRoute: "/unauthorized"
    },
    qa: {
        production: false,
        envName: 'qa',
        //apiBaseUrl: 'https://f8ux11iqhb.execute-api.us-east-1.amazonaws.com/qa/api/',
        apiBaseUrl: 'https://graph.microsoft.com/v2.0/me', // QAJ 8/29/24 Updated apiBaseUrl
        azureClientId: '060cdbe9-e3cb-446e-9905-834750e784bc',
        unauthorizedRoute: "/unauthorized"
    },

    prod: {
        production: true,
        envName: 'prod',
        //apiBaseUrl: 'https://3f136d8gz6.execute-api.us-east-1.amazonaws.com/prod/api/',
        apiBaseUrl: 'https://graph.microsoft.com/v2.0/me', // QAJ 8/29/24 Updated apiBaseUrl
        azureClientId: 'aa1b25f8-c99c-4621-864b-30d0ef885b73',
        unauthorizedRoute: "/unauthorized"
    }
};